/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Dialog } from 'vant';
export default {
  name: 'InductionDetails',
  components: {},
  props: {},
  data: function data() {
    return {
      close: 0,
      depName: '',
      dep: '',
      depShowPicker: false,
      depColumns: [{
        value: 0,
        text: '市场部'
      }, {
        value: 1,
        text: '研发部'
      }],
      ApproverName: '',
      Approver: '',
      ApproverShowPicker: false,
      ApproverColumns: [{
        value: 0,
        text: '张三'
      }, {
        value: 1,
        text: '李四'
      }],
      authorityName: '',
      authority: '',
      authorityShowPicker: false,
      authorityColumns: [{
        value: 0,
        text: '权限一'
      }, {
        value: 1,
        text: '权限二'
      }],
      invalidName: '否',
      invalid: '0',
      invalidShowPicker: false,
      invalidColumns: [{
        value: 0,
        text: '否'
      }, {
        value: 1,
        text: '是'
      }],
      // FileEnable: +this.$utils.Store.getLocalItem('FileEnable'),
      FileEnable: true,
      pushValue_num: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
      InductionDate: ''
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getSysDate();
  },
  mounted: function mounted() {},
  methods: {
    Induction: function Induction() {
      var _this = this;

      Dialog.confirm({
        title: '入职确认',
        message: '请确认是否进行入职操作？',
        confirmButtonColor: '#3598FE',
        confirmButtonText: '确定'
      }).then(function () {
        // on confirm
        _this.$router.push({
          path: '/FileDetail' // query: {
          //     personid: item.personid,
          // },

        });
      }).catch(function () {// on cancel
      });
    },
    getSysDate: function getSysDate() {
      var _this2 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.InductionDate = data.sysDate.substring(0, 10); // this.InductionDate = data.sysDate
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    IDInfo: function IDInfo() {
      if (this.close == 0) {
        this.close = 1;
      } else {
        this.close = 0;
      }
    },
    depConfirm: function depConfirm(item) {
      this.dep = item.value;
      this.depName = item.text;
      this.depShowPicker = false;
    },
    ApproverConfirm: function ApproverConfirm(item) {
      this.Approver = item.value;
      this.ApproverName = item.text;
      this.ApproverShowPicker = false;
    },
    authorityConfirm: function authorityConfirm(item) {
      this.authority = item.value;
      this.authorityName = item.text;
      this.authorityShowPicker = false;
    },
    invalidConfirm: function invalidConfirm(item) {
      this.invalid = item.value;
      this.invalidName = item.text;
      this.invalidShowPicker = false;
    },
    pushClick: function pushClick() {
      if (this.pushValue == true) {
        this.pushValue_num = 0;
      } else if (this.pushValue == false) {
        this.pushValue_num = 1;
      } // this.$utils.Tools.loadShow()
      //
      // this.$api.My.pushMessage({
      //     userSerial: this.$utils.Store.getItem('userSerial'),
      //     sendStatus: this.pushValue_num,
      // })
      //     .then((response) => {
      //         this.$utils.Tools.loadHide()
      //         const { code, msg, data } = response
      //         if (+code !== this.$code.success) {
      //             this.$utils.Tools.toastShow(msg)
      //         } else {
      //             this.$utils.Store.setLocalItem('pushValue', this.pushValue)
      //         }
      //     })
      //     .catch((err) => {})

    }
  }
};